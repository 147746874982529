.app {
    display: flex;
    align-items: stretch;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
  }

  .header-content {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0px;
  }
  
  .under-header-content {
    font-size: 18px;
    line-height: 24px;
  }