.sidebar-container {
    display: flex;
    flex: 0 0 280px;
    flex-direction: column;
    height: 100svh; /* Set the container height to fill the viewport */
    max-width: 300px;
    width: 40vh;
  }

  @media screen and (max-width: 767px) {
    .sidebar-container {
      position: fixed;
      left: -280px; /* Hide sidebar by default */
      top: 0;
      bottom: 0;
      transition: left 0.3s ease;
      display: block; /* Override previous display: none */
      z-index: 1000;
    }

    .sidebar-container.open {
      left: 0;
    }

    .hamburger-menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 25px;
      position: fixed;
      top: 28.5px;
      left: 20px;
      z-index: 999; /* Lower z-index than sidebar */
      cursor: pointer;
    }

    .hamburger-menu span {
      display: block;
      width: 100%;
      height: 6px;
      background-color: #c62027; /* Changed to your red color */
      border-radius: 3px;
    }

    .sidebar-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      z-index: 999;
    }

    .sidebar-overlay.active {
      opacity: 1;
      visibility: visible;
    }

    .sidebar {
      height: 100vh;
      width: 280px;
    }
  }
  
  /* Hide hamburger menu on desktop */
  @media screen and (min-width: 768px) {
    .hamburger-menu {
      display: none;
    }
    
    .sidebar-overlay {
      display: none;
    }
  }
  
  .sidebar {
    background-color: #ffc907;
    padding: 20px;
    border-right: 2px solid #1E1F21; /* Dark Gray */
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow-y: hidden;
    padding-bottom: 15px;
    position: relative;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
  }
  
  .sidebar li {
    cursor: pointer;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  
  .sidebar li:hover {
    background-color: #e89741;
  }
  
  .sidebar li.selected {
    background-color: #c37021;
    color: #f2dfd0;
    font-weight: bold;
  }
  
  .file-upload {
    height: 35vh;
  }
  
  .list-section {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 2px solid #555;
    font-family: 'Futura', sans-serif;
  }

  .sidebar-header{
    font-size: 1.5rem;
  }
  
  .custom-file-input {
    margin-bottom: 15px;
  }
  
  .custom-file-input input[type="file"] {
    display: none;
  }
  
  .custom-file-input .file-label {
    background-color: #555;
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .custom-file-input .file-name {
    margin-top: 5px;
    font-size: 14px;
    color: #aaa;
  }
  
  .error-message {
    color: #cc0000;
    font-size: 16px;
    font-style: italic;
    margin: 2px;
  }