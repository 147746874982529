.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #ffc907;
  margin-top: -6vh;
}

.welcome {
  text-align: center;
  font-size: 2.25rem;
}

.auth-container input {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  font-family: 'Futura';
}

.auth-container h2 {
  margin-bottom: 20px;
}

.auth-container label {
  margin-bottom: 10px;
}

.auth-container button {
  font-family: 'UnionMade', sans-serif;
  font-size: 18px;
  margin-top: 20px; /* Add top margin to both buttons */
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #555;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.auth-container button:hover {
  background-color: #007479;
}
