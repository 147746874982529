.centre-text {
    text-align: center;
    max-width: 46rem;
    margin: 0 auto;
}

.heading.large {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.1;
    text-transform: uppercase;
}

.spacer-80 {
    height: 5rem;
}

.w-layout-grid.card-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
} 

@media (max-width: 1440px) {
    .w-layout-grid.card-list {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    }
}

@media (max-width: 767px) {
    .w-layout-grid.card-list {
        grid-template-columns: 1fr; /* 1 column on small screens */
    }
}