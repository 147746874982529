.screenplay-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Set the container height to fill the viewport */
    width: 60vh;
    flex: auto;
  }
  
  .screenplay {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%; /* Set the height of the parent div */
    flex: 1; /* Allow the sidebar to expand and fill the available space */
    overflow-y: hidden; /* Enable vertical scrolling if needed */
    padding-bottom: 20px; /* Add some padding to accommodate the fixed profile button */
    position: relative; /* Add position relative to the sidebar */
  }

  @media screen and (max-width: 767px) {
    .screenplay {
      padding: 20px;
      display: flex;
      flex-direction: column;
      height: 100%; /* Set the height of the parent div */
      width: 100vw;
      flex: 1; /* Allow the sidebar to expand and fill the available space */
      overflow-y: hidden; /* Enable vertical scrolling if needed */
      padding-bottom: 20px; /* Add some padding to accommodate the fixed profile button */
      position: relative; /* Add position relative to the sidebar */
    }
  }
  
  
  .screenplay-header {
    flex-direction: row;
    display: flex;
  }
  
  @media screen and (max-width: 767px) {
    .screenplay-header {
      margin-left: 50px;
    }
  }
  .loading-wheel {
    display: inline-block;
    width: 400px; /* Adjust the width and height as needed */
    height: 200px;
    background: url('../public/carousel4.gif') no-repeat center;
    background-size: cover;
  }