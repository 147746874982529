.nav {
    z-index: 2;
    background-color: var(--100);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
    position: sticky;
    top: 0;
}

.nav-container {
    max-width: 100rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5rem;
    padding-right: 5rem;
}

@media screen and (max-width: 767px) {
    .nav-container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}


.logo-wrapper {
    display: flex;
    align-items: center;
}

.logo {
    color: var(--900);
    text-decoration: none;
}

.actions-wrapper {
    display: flex;
    align-items: center;
}

.nav-gradient-underline {
    background-image: radial-gradient(circle, #000, #007479 0%, #fff);
    width: 100%;
    height: 0.5rem;
    position: absolute;
    bottom: 0;
} 