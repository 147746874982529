:root {
  --100: white;
  --900: #0f172a;
  --accent-light: #ffe6ed;
  --600: #64748b;
  --200: #f7f7f7;
  --300: #f0f0f0;
  --700: #455366;
  --accent: #c62027;
  --400: #ebebeb;
  --yellow: #ffc907;
  --red: #c62027;
  --800: #263142;
  --teal: #007479;
}

/* Base resets */
* {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
  height: 100%;
}

body {
  margin: 0;
  color: #333;
  background-color: #fff;
  min-height: 100%;
  font-size: 14px;
  line-height: 20px;
}

/* Base HTML elements */
h1, h2, h3, h4, h5, h6 {
  margin-top: 1rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.section {
  padding: 3rem 5rem;
  background-color: var(--yellow);
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  border: 0;
}

/* Button styles */
.button {
  background-color: var(--red);
  color: var(--100);
  text-align: center;
  text-transform: uppercase;
  border-radius: 6.25rem;
  flex: none;
  min-height: 3.5rem;
  padding: 1rem 1.75rem;
  font-family: Geist Mono, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  transition: transform .25s, opacity .25s;
  border: 0;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  opacity: .88;
}

.button:active {
  transform: scale(.98);
}

.button-small {
  padding: .5625rem 1.25rem;
  min-height: 2.25rem;
}

/* Utility classes */
.spacer-16 {
  height: 1rem;
}

.spacer-24 {
  height: 1.5rem;
}

.spacer-64 {
  height: 4rem;
}

.spacer-80 {
  height: 5rem;
}

/* Media queries */
@media screen and (max-width: 767px) {

  .section {
    padding: 5rem 3rem;
  }

  .container-large {
    width: 100%;
  }

  .heading.large {
    font-size: 2.5rem;
  }

  .hide-mobile-portrait {
    display: none;
  }
}

@media screen and (max-width: 479px) {

  p {
    font-size: .9375rem;
  }

  .section {
    padding: 3.5rem 2.5rem;
  }

  .heading.large {
    font-size: 2.125rem;
  }

  .paragraph {
    font-size: .875rem;
  }

  .paragraph.large {
    font-size: 1.0625rem;
  }
}

/* Webflow font */
@font-face {
  font-family: 'Geist Mono';
  src: url('https://cdn.prod.website-files.com/675ee4fe4314f55d5e79e7fc/675ee4fe4314f55d5e79e8c0_GeistMonoVariableVF.woff2') format('woff2');
  font-weight: 100 1100;
  font-style: normal;
  font-display: swap;
}