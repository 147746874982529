.tabs {
    flex-grow: 1;
    display: contents; 
  }

  @media screen and (max-width: 767px) {
    .tabs {
      flex-grow: 1;
      overflow: scroll;
      display: block;
    }
  }
  
  .react-tabs__tab {
    background: #c37021;
    color: #f2dfd0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    margin-right: 2px;
    margin-bottom: 2px;
  }

  .react-tabs__tab-list{ 
    max-width: 900px;
  }
  
  .tabs-selected {
    color: #c62027;
    background: #ffc907;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: bold;
  }
  
  .details-container {
    overflow: auto;
    font-family: 'Futura', sans-serif;
  }
  
  .details-container table {
    width: 70%;
    border-collapse: collapse;
  }
  
  .details-container th, .details-container td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .details-container tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .details-container th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2;
  }