@font-face {
  font-family: 'UnionMade';
  src: url('./fonts/union-made/OpenType-TT/Union Made.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/futura/futura medium bt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'UnionMade', serif;
  color: #007479; /* Deep Teal */
  background-color: #f5e7dc;
  margin: 0px;
}

/* Set default font for all headers */
h1, h2, h3, h4, h5, h6 {
  font-family: 'UnionMade', serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5e7dc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007479;
}
