.header-layout {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.header-content-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-description-wrapper {
    flex: 1;
    margin-right: 20px;
}

.paragraph.large {
    font-size: 1.25rem;
    line-height: 1.45;
    text-align: left;
}

.hero-image-wrapper {
    flex-shrink: 0;
    max-width: 50%;
    width: 100%;
}

.hero-image {
    width: 100%;
    height: auto;
}

.hero-video {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 767px) {
    .header-content-bottom {
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
    }

    .header-description-wrapper {
        max-width: 100%;
        margin-right: 0;
    }

    .paragraph.large {
        font-size: 1rem;
        line-height: 1.5;
        text-align: left;
    }

    .hero-image-wrapper {
        width: 100%;
        min-width: 100%;
        margin-top: 1rem;
    }

    .heading.large {
        font-size: 2rem;
        text-align: left;
    }
} 