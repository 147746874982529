.profile-page {
    padding: 30px;
  }
  
  .logout-button {
    font-family: 'UnionMade', sans-serif;
    margin-top: 40px;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007479; /* Deep Teal */
    color: #F2DC9F; /* Muted Yellow */
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .profile-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 3px;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .profile-icon:hover {
    background-color: #e0e0e0;
  }
  
  .profile-image {
    width: 50px; /* Adjust the width as needed */
    height: 50px; /* Adjust the height as needed */
    margin-right: 8px; /* Add some spacing between icon and text */
  }