.card-item {
    background-color: var(--100);
    padding: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-large {
    width: 3.5rem;
    height: 3.5rem;
}

.spacer-64 {
    height: 4rem;
}

.spacer-16 {
    height: 1rem;
}

.paragraph {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
}

.corner-cuts-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: var(--red)
}

.corner-cut-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.corner-cut-top-left,
.corner-cut-top-right,
.corner-cut-bottom-right,
.corner-cut-bottom-left {
    position: absolute;
    width: 2rem;
    height: 2rem;
}

.corner-cut-top-left {
    top: 0;
    left: 0;
}

.corner-cut-top-right {
    top: 0;
    right: 0;
}

.corner-cut-bottom-right {
    bottom: 0;
    right: 0;
}

.corner-cut-bottom-left {
    bottom: 0;
    left: 0;
}

@media screen and (max-width: 767px) {
    .card-item {
        padding: 2rem;
    }

    .icon-large {
        width: 2.5rem;
        height: 2.5rem;
    }
} 