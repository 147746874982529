.section.secondary {
    background-color: var(--200);
}

.footer-flex-container-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--400);
    padding-bottom: 3rem;
    margin-bottom: 3rem;
}

.footer-column {
    display: flex;
    align-items: center;
}

.logo {
    color: var(--900);
    text-decoration: none;
}

.footer-link {
    color: var(--900);
    text-decoration: none;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media screen and (max-width: 767px) {
    .footer-flex-container-top {
        flex-direction: column;
        align-items: center;
    }

    .footer-link {
        margin-top: 1rem;
    }

    .logo {
        font-size: 1.2rem;
    }
} 